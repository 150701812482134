(function(){

    if (typeof NodeList.prototype.forEach === "undefined") {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }

    if (typeof HTMLCollection.prototype.forEach === "undefined") {
        HTMLCollection.prototype.forEach = Array.prototype.forEach;
    }

    let homeCatBlock = document.getElementById('home-category-block'),
        productListPage = document.getElementById('product-list-page'),
        paymentPage = document.getElementById('payment-page'),
        confirmPage = document.getElementById('confirmation-page'),
        invoicePage = document.getElementById('InvoicePage'),
        printBtn = document.getElementById('print-page'),
        burger = document.getElementById('hamburger-btn'),
        mobileMenu = document.getElementById('mobile-menu'),
        //mmCloseBtn = document.querySelector('#mobile-menu button'),
        dropMenus = document.querySelectorAll('li.has-submenu'),
        searchBtn = document.querySelector('.start-search');

        searchBtn.addEventListener('click', function(e){
        searchBtn.parentNode.classList.toggle('open');

    });

    if(confirmPage || invoicePage)
    {
        printBtn.addEventListener('click', function(evt){
            evt.preventDefault();
            window.print();
        });
    }

    burger.addEventListener('click', function(){
        burger.classList.toggle('is-active');
        mobileMenu.classList.toggle('open');
    });

    dropMenus.forEach(function(elm){
        elm.addEventListener('click', function(){
            elm.classList.toggle('open');
        });
    });

    window.addEventListener('load', function(){
        // Load hirez images and swap
        const images = document.querySelectorAll('img.load');
        const banners = document.querySelectorAll('img.load-a');

        if(homeCatBlock)
        {
            loadBanners(banners);
        }
        if(document.getElementById('ProductListPage'))
        {
            loadImages(images);
        }
    });

    var debounceTimeout = null;

    let searchApp = new Vue({
        el: '#search-box',
        data: {
            Results: false,
            Source: ''
        },
        created: function()
        {

        },
        methods: {
            search: function(evt){
                const term = evt.target.value;
                const self = this;

                clearTimeout(debounceTimeout);
                debounceTimeout = setTimeout(searchOptions, 300);

                function searchOptions(){

                    if (this.Source) {
                        this.Source.cancel();
                    }
                    const request = cancelableGet(term);
                    this.Source = request.Source;
                    request.promise.then(response => setResponse(response.data));

                    function setResponse(data){
                        self.Results = data;
                        //console.log(data);
                    }
                }
            },
            closeSearch: function(evt){
                document.querySelector('.cell.search').classList.toggle('open');
            }
        },
        computed: {

        }
    });

    let footerContactApp = new Vue({
		el: '#footer-contact-form',
		data: {
			name:  {
				label: document.getElementById('Form_FooterContactForm_name').getAttribute('placeholder'),
				value: null
			},
			email: {
				label: document.getElementById('Form_FooterContactForm_email').getAttribute('placeholder'),
				value: null
			},
			phone: {
				label: document.getElementById('Form_FooterContactForm_phone').getAttribute('placeholder'),
				value: null
			},
			msg: {
				label: document.getElementById('Form_FooterContactForm_message').getAttribute('placeholder'),
				value: null
			},
			challenge: {
				label: document.getElementById('Form_FooterContactForm_challenge').getAttribute('placeholder'),
				value: null
			},
			errors: [],
			success: null
		},
		created: function(){

		},
		methods: {
			sendForm: function(evt){

				this.errors = [];

				const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
				const reqLabel = (lang === 'en-CA' ? 'required' : 'Champs obligatoires');
				const validLabel = (lang === 'en-CA' ? 'not valid' : 'pas valide');

				this.phone.value = this.formatPhoneNumber(this.phone.value);
				console.log(this.validEmail(this.email.value));
				!(this.name.value) ? this.errors.push(this.name.label+': '+reqLabel) : null;
				!(this.validEmail(this.email.value)) ? this.errors.push(this.email.label+': '+validLabel) : null;
				//!(this.email.value) ? this.errors.push(this.email.label+': '+reqLabel) : null;
				!(this.phone.value) ? this.errors.push(this.phone.label+': '+reqLabel) : null;
				!(this.msg.value) ? this.errors.push(this.msg.label+': '+reqLabel) : null;
				!(this.challenge.value) ? this.errors.push(this.challenge.label+': '+reqLabel) : null;

				const params = {
					name: encodeURIComponent(this.name.value),
					email: encodeURIComponent(this.email.value),
					phone: encodeURIComponent(this.phone.value),
					msg: encodeURIComponent(this.msg.value),
					challenge: encodeURIComponent(this.challenge.value),
					catch: encodeURIComponent(document.getElementById('Form_FooterContactForm_catch').value),
					token: document.getElementById('Form_FooterContactForm_SecurityID').value
				};
				const self = this;

				if(!(this.errors.length)){
					axios.post('/form/SendFooterContact',params)
						.then(function(resp){
							console.log(resp.data);
							if(resp.data.errors.length){
								self.errors = resp.data.errors;
							} else {
								self.success = resp.data.SuccessMsg;
							}

						})
						.catch(function(e){
							console.log(e);
						});
				}
			},
			formatPhoneNumber: function(s) {
     			var s2 = (""+s).replace(/\D/g, '');
     			var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
     			return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
 			},
			validEmail: function (email) {
				const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			}
		}
	})

}());
function slide(myswiper){
    myswiper.slideNext();

}
function cancelableGet(term) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const promise = axios.get('/results/ReturnResults/?term='+term, {
        cancelToken: source.token
    }).catch(function(thrown) {
        if (axios.isCancel(thrown)) {
            //console.log('Request canceled', thrown.message);
        } else {
            // handle error
        }
    });
    return {
        promise, source
    }
}
function loadBgImages(links)
{
    links.forEach(function(elm){
        const lgsrc = elm.dataset.lgsrc;
        const tempImage = new Image();

        tempImage.addEventListener('load', function(e){

            elm.style.backgroundImage = "url('"+lgsrc+"')";
        });

        tempImage.src = lgsrc;
    });
}
function loadImages(images){
    let index = 1;
    images.forEach(function(elm){
        const tempImage = new Image();
        const lgSrc = elm.dataset.lgsrc;
        if(index < images.length)
        {
            tempImage.src = lgSrc;
            tempImage.addEventListener('load', function(e){
                elm.src = lgSrc;
            });
        } else {

            tempImage.src = lgSrc;

            tempImage.addEventListener('load', function(e){
                elm.src = lgSrc;

            });
        }

        index = index + 1;
    });
}
function loadBanners(banners)
{
    let index = 1;
    banners.forEach(function(elm){
        const tempImage = new Image();
        const lgSrc = elm.dataset.lgsrc;
        if(index < banners.length)
        {
            tempImage.src = lgSrc;
            tempImage.addEventListener('load', function(e){
                elm.src = lgSrc;
            });
        } else {

            tempImage.src = lgSrc;

            tempImage.addEventListener('load', function(e){
                const images = document.querySelectorAll('img.load');
                const links = document.querySelectorAll('a.load');
                elm.src = lgSrc;
                loadBgImages(links);
                loadImages(images);
                // Timeout added as swiper was cloning slide (chrome only) before link was swapped out for hirez image
                var timer1 = setTimeout(function(){
                    var mySwiper = new Swiper ('.swiper-container', {
                        // Optional parameters
                        direction: 'horizontal',
                        loop: true,
                        speed: 400,
                        slidesPerView: 1,
                        pagination: {
                            el: '.swiper-pagination'
                        }
                    });
                    var timer = setInterval(function(){
                        mySwiper.slideNext();
                    }, 6000);
                }, 100);

            });
        }

        index = index + 1;
    });
}

// function formatPhoneNumber(s) {
//     var s2 = (""+s).replace(/\D/g, '');
//     var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
//     return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
// }
// function sendFooterContact(name, email,msg)
// {
//     let formSubmit = document.querySelector('#Form_QuickContactForm input[type=submit]');
//     const params = {
//         name: 'paul',
//         email: 'paul@cultivateteam.com',
//         phone: '403-830-8384',
//         msg: 'test'
//     }
//
//
// }
